body {
  padding: 10px !important;
  margin: 10 px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #eee !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Viewcontainer {
  border: 0px solid black;
  margin: auto;
  border-radius: 5px;
  box-shadow: 5px 5px 10px grey;
  background-color: white;
  
  
}

.canvasFrame {
  margin: 5px;
  width: 100%;
  border: 1px solid black;
  
}

canvas {
  width: 100%;
}

.config {
  background-color: #eee !important;
}

#inpTableWidth:focus, #inpSinkWidth:focus, #inpSinkPos:focus {
  background-color: rgb(139, 166, 255);
  color: white;
  font-weight: bold;
}

.requestform {
  top: 50%;
  margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;
  z-index: 999;
  border: 1px solid black;
}